// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* source for Roboto font */
body {
  margin: 0;
  line-height: normal;
  font-family: "Roboto", sans-serif; /* Apply Roboto font to the body */
}

:root {
  /* fonts */
  --m3-font: Roboto;

  /* font sizes */

  /* Colors */
  --color-white: #fff;
  --m3-sys-light-on-surface-variant: #49454f;
  --color-gainsboro: #d9d9d9;
  --m3-sys-light-surface: #fef7ff;

  /* Paddings */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,2BAA2B;AAE3B;EACE,SAAS;EACT,mBAAmB;EACnB,iCAAiC,EAAE,kCAAkC;AACvE;;AAEA;EACE,UAAU;EACV,iBAAiB;;EAEjB,eAAe;;EAEf,WAAW;EACX,mBAAmB;EACnB,0CAA0C;EAC1C,0BAA0B;EAC1B,+BAA+B;;EAE/B,aAAa;AACf","sourcesContent":["/* source for Roboto font */\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap\");\nbody {\n  margin: 0;\n  line-height: normal;\n  font-family: \"Roboto\", sans-serif; /* Apply Roboto font to the body */\n}\n\n:root {\n  /* fonts */\n  --m3-font: Roboto;\n\n  /* font sizes */\n\n  /* Colors */\n  --color-white: #fff;\n  --m3-sys-light-on-surface-variant: #49454f;\n  --color-gainsboro: #d9d9d9;\n  --m3-sys-light-surface: #fef7ff;\n\n  /* Paddings */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
