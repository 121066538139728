/* tslint:disable */
/* eslint-disable */
/**
 * Securities API
 * This API provides endpoint for issuing and purchasing securities on the external CBDC network.  The service is currently limited to the securities offered directly by a single issuer, who is the owner of the service. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AssetRef
 */
export interface AssetRef {
    /**
     * The type of the token
     * @type {string}
     * @memberof AssetRef
     */
    TokenType: string;
    /**
     * The ID of the asset reference
     * @type {string}
     * @memberof AssetRef
     */
    AssetRefId: string;
    /**
     * The owner of the asset
     * @type {string}
     * @memberof AssetRef
     */
    Owner: string;
    /**
     * The remote recipient of the asset
     * @type {string}
     * @memberof AssetRef
     */
    RemoteRecipient: string;
    /**
     * The amount of refwNOK
     * @type {number}
     * @memberof AssetRef
     */
    refwNOKAmount: number;
}
/**
 * 
 * @export
 * @interface BridgeOutRequest
 */
export interface BridgeOutRequest {
    /**
     * The ID of the asset reference
     * @type {string}
     * @memberof BridgeOutRequest
     */
    AssetRefId: string;
    /**
     * The amount of the asset
     * @type {number}
     * @memberof BridgeOutRequest
     */
    Amount: number;
    /**
     * The remote recipient of the asset
     * @type {string}
     * @memberof BridgeOutRequest
     */
    RemoteRecipient: string;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    address: string;
}
/**
 * Represents an order to purchase a bond.
 * @export
 * @interface CreateOrder
 */
export interface CreateOrder {
    /**
     * The ISIN of the requested bond.
     * @type {string}
     * @memberof CreateOrder
     */
    isin: string;
    /**
     * The volume requested in units of the bond.
     * @type {number}
     * @memberof CreateOrder
     */
    volume: number;
}
/**
 * A request object for issuing a new ZeroBond. ISIN and LEI will be determined by the backend and cannot be specified in the request. 
 * @export
 * @interface CreateZeroBond
 */
export interface CreateZeroBond {
    /**
     * The total volume offered (in bond units).
     * @type {number}
     * @memberof CreateZeroBond
     */
    totalVolume: number;
    /**
     * The nominal start time of the bond.
     * @type {string}
     * @memberof CreateZeroBond
     */
    startTime: string;
    /**
     * The nominal maturity time of the bond.
     * @type {string}
     * @memberof CreateZeroBond
     */
    maturityTime: string;
    /**
     * The issue price (in NOK) at which a single bond unit is offered. 
     * @type {number}
     * @memberof CreateZeroBond
     */
    issuePrice: number;
    /**
     * The face value (in NOK) of a single bond unit.  The bond is redeemable at face value. 
     * @type {number}
     * @memberof CreateZeroBond
     */
    faceValue: number;
}
/**
 * 
 * @export
 * @interface EscrowRequest
 */
export interface EscrowRequest {
    /**
     * The recipient in the remote network
     * @type {string}
     * @memberof EscrowRequest
     */
    remoteRecipient: string;
    /**
     * The identifier for the remote network
     * @type {string}
     * @memberof EscrowRequest
     */
    remoteNetwork: string;
    /**
     * The amount to be escrowed
     * @type {number}
     * @memberof EscrowRequest
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    timestamp: string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    error: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    path?: string;
}
/**
 * Represents a submitted order for the purchase of a bond. 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * Unique internal identifier issued to this request.
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * The volume requested in bond units.
     * @type {number}
     * @memberof Order
     */
    volume: number;
    /**
     * The blockchain address of the buyer.  This will be used to confirm payment of the issue price and for issuing bond tokens. 
     * @type {string}
     * @memberof Order
     */
    address: string;
    /**
     * The time at which the order was created. 
     * @type {string}
     * @memberof Order
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status: OrderStatusEnum;
    /**
     * 
     * @type {ZeroBond}
     * @memberof Order
     */
    zeroBond: ZeroBond;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderStatusEnum {
    Requested = 'requested',
    Paid = 'paid',
    Minted = 'minted',
    Redeemed = 'redeemed'
}

/**
 * 
 * @export
 * @interface Security
 */
export interface Security {
    /**
     * Unique internal identifier issued to this request.
     * @type {string}
     * @memberof Security
     */
    id: string;
    /**
     * The ISIN (International Securities Identification Number) is a 12-character alphanumeric code which provides uniform identification of a security. 
     * @type {string}
     * @memberof Security
     */
    isin: string;
    /**
     * The LEI (Legal Entity Identifier) is a 20-character alphanumeric code which provides a unique global identifier for legal entities participating in financial transactions. 
     * @type {string}
     * @memberof Security
     */
    issuerLei: string;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    securityType: string;
}
/**
 * 
 * @export
 * @interface ServiceStatus
 */
export interface ServiceStatus {
    /**
     * Indicates if the service is alive
     * @type {boolean}
     * @memberof ServiceStatus
     */
    isAlive?: boolean;
}
/**
 * A security representing a zero-coupon bond.
 * @export
 * @interface ZeroBond
 */
export interface ZeroBond extends Security {
    /**
     * The total volume offered (in bond units).
     * @type {number}
     * @memberof ZeroBond
     */
    totalVolume: number;
    /**
     * The nominal start time of the bond.
     * @type {string}
     * @memberof ZeroBond
     */
    startTime: string;
    /**
     * The nominal maturity time of the bond.
     * @type {string}
     * @memberof ZeroBond
     */
    maturityTime: string;
    /**
     * The issue price (in NOK) at which a single bond unit is offered. 
     * @type {number}
     * @memberof ZeroBond
     */
    issuePrice: number;
    /**
     * The face value (in NOK) of a single bond unit.  The bond is redeemable at face value. 
     * @type {number}
     * @memberof ZeroBond
     */
    faceValue: number;
    /**
     * 
     * @type {string}
     * @memberof ZeroBond
     */
    status: ZeroBondStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ZeroBondStatusEnum {
    Requested = 'requested',
    Deployed = 'deployed'
}

/**
 * 
 * @export
 * @interface ZeroBondAllOf
 */
export interface ZeroBondAllOf {
    /**
     * The total volume offered (in bond units).
     * @type {number}
     * @memberof ZeroBondAllOf
     */
    totalVolume: number;
    /**
     * The nominal start time of the bond.
     * @type {string}
     * @memberof ZeroBondAllOf
     */
    startTime: string;
    /**
     * The nominal maturity time of the bond.
     * @type {string}
     * @memberof ZeroBondAllOf
     */
    maturityTime: string;
    /**
     * The issue price (in NOK) at which a single bond unit is offered. 
     * @type {number}
     * @memberof ZeroBondAllOf
     */
    issuePrice: number;
    /**
     * The face value (in NOK) of a single bond unit.  The bond is redeemable at face value. 
     * @type {number}
     * @memberof ZeroBondAllOf
     */
    faceValue: number;
    /**
     * 
     * @type {string}
     * @memberof ZeroBondAllOf
     */
    status: ZeroBondAllOfStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ZeroBondAllOfStatusEnum {
    Requested = 'requested',
    Deployed = 'deployed'
}


/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the wNOK* balance of the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the wNOK* balance of the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalance(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await BalanceApiAxiosParamCreator(configuration).getBalance(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns the wNOK* balance of the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(options?: any): AxiosPromise<number> {
            return BalanceApiFp(configuration).getBalance(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * 
     * @summary Returns the wNOK* balance of the authenticated user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public getBalance(options?: any) {
        return BalanceApiFp(this.configuration).getBalance(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BondApi - axios parameter creator
 * @export
 */
export const BondApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Requests the creation of a new bond offering.
         * @param {CreateZeroBond} [createZeroBond] Bond creation request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBond: async (createZeroBond?: CreateZeroBond, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bonds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createZeroBond !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createZeroBond !== undefined ? createZeroBond : {}) : (createZeroBond || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the bond carrying the ISIN provided.
         * @param {string} isin The ISIN of the bond to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBond: async (isin: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            if (isin === null || isin === undefined) {
                throw new RequiredError('isin','Required parameter isin was null or undefined when calling getBond.');
            }
            const localVarPath = `/api/bonds/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all bonds that the user has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonds: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bonds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BondApi - functional programming interface
 * @export
 */
export const BondApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Requests the creation of a new bond offering.
         * @param {CreateZeroBond} [createZeroBond] Bond creation request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBond(createZeroBond?: CreateZeroBond, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeroBond>> {
            const localVarAxiosArgs = await BondApiAxiosParamCreator(configuration).createBond(createZeroBond, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the bond carrying the ISIN provided.
         * @param {string} isin The ISIN of the bond to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBond(isin: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeroBond>> {
            const localVarAxiosArgs = await BondApiAxiosParamCreator(configuration).getBond(isin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all bonds that the user has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonds(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZeroBond>>> {
            const localVarAxiosArgs = await BondApiAxiosParamCreator(configuration).getBonds(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BondApi - factory interface
 * @export
 */
export const BondApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Requests the creation of a new bond offering.
         * @param {CreateZeroBond} [createZeroBond] Bond creation request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBond(createZeroBond?: CreateZeroBond, options?: any): AxiosPromise<ZeroBond> {
            return BondApiFp(configuration).createBond(createZeroBond, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the bond carrying the ISIN provided.
         * @param {string} isin The ISIN of the bond to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBond(isin: string, options?: any): AxiosPromise<ZeroBond> {
            return BondApiFp(configuration).getBond(isin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all bonds that the user has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonds(options?: any): AxiosPromise<Array<ZeroBond>> {
            return BondApiFp(configuration).getBonds(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BondApi - object-oriented interface
 * @export
 * @class BondApi
 * @extends {BaseAPI}
 */
export class BondApi extends BaseAPI {
    /**
     * 
     * @summary Requests the creation of a new bond offering.
     * @param {CreateZeroBond} [createZeroBond] Bond creation request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BondApi
     */
    public createBond(createZeroBond?: CreateZeroBond, options?: any) {
        return BondApiFp(this.configuration).createBond(createZeroBond, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the bond carrying the ISIN provided.
     * @param {string} isin The ISIN of the bond to fetch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BondApi
     */
    public getBond(isin: string, options?: any) {
        return BondApiFp(this.configuration).getBond(isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all bonds that the user has access to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BondApi
     */
    public getBonds(options?: any) {
        return BondApiFp(this.configuration).getBonds(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BridgeApi - axios parameter creator
 * @export
 */
export const BridgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Triggers the bridging out operation
         * @param {BridgeOutRequest} [bridgeOutRequest] A bridge out request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bridgeOutWNOK: async (bridgeOutRequest?: BridgeOutRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/bridgeOutWNOK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bridgeOutRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bridgeOutRequest !== undefined ? bridgeOutRequest : {}) : (bridgeOutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers the creating of a AssetRef in the bridge for the escrowed amount
         * @param {EscrowRequest} [escrowRequest] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escrowWNOK: async (escrowRequest?: EscrowRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/escrowWNOK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof escrowRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(escrowRequest !== undefined ? escrowRequest : {}) : (escrowRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/getStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnAssetRefs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/getOwnAssetRefs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BridgeApi - functional programming interface
 * @export
 */
export const BridgeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Triggers the bridging out operation
         * @param {BridgeOutRequest} [bridgeOutRequest] A bridge out request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bridgeOutWNOK(bridgeOutRequest?: BridgeOutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BridgeApiAxiosParamCreator(configuration).bridgeOutWNOK(bridgeOutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Triggers the creating of a AssetRef in the bridge for the escrowed amount
         * @param {EscrowRequest} [escrowRequest] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async escrowWNOK(escrowRequest?: EscrowRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BridgeApiAxiosParamCreator(configuration).escrowWNOK(escrowRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBridgeStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStatus>> {
            const localVarAxiosArgs = await BridgeApiAxiosParamCreator(configuration).getBridgeStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnAssetRefs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetRef>>> {
            const localVarAxiosArgs = await BridgeApiAxiosParamCreator(configuration).getOwnAssetRefs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BridgeApi - factory interface
 * @export
 */
export const BridgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Triggers the bridging out operation
         * @param {BridgeOutRequest} [bridgeOutRequest] A bridge out request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bridgeOutWNOK(bridgeOutRequest?: BridgeOutRequest, options?: any): AxiosPromise<boolean> {
            return BridgeApiFp(configuration).bridgeOutWNOK(bridgeOutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers the creating of a AssetRef in the bridge for the escrowed amount
         * @param {EscrowRequest} [escrowRequest] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escrowWNOK(escrowRequest?: EscrowRequest, options?: any): AxiosPromise<boolean> {
            return BridgeApiFp(configuration).escrowWNOK(escrowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeStatus(options?: any): AxiosPromise<ServiceStatus> {
            return BridgeApiFp(configuration).getBridgeStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of own asset references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnAssetRefs(options?: any): AxiosPromise<Array<AssetRef>> {
            return BridgeApiFp(configuration).getOwnAssetRefs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BridgeApi - object-oriented interface
 * @export
 * @class BridgeApi
 * @extends {BaseAPI}
 */
export class BridgeApi extends BaseAPI {
    /**
     * 
     * @summary Triggers the bridging out operation
     * @param {BridgeOutRequest} [bridgeOutRequest] A bridge out request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgeApi
     */
    public bridgeOutWNOK(bridgeOutRequest?: BridgeOutRequest, options?: any) {
        return BridgeApiFp(this.configuration).bridgeOutWNOK(bridgeOutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers the creating of a AssetRef in the bridge for the escrowed amount
     * @param {EscrowRequest} [escrowRequest] A purchase request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgeApi
     */
    public escrowWNOK(escrowRequest?: EscrowRequest, options?: any) {
        return BridgeApiFp(this.configuration).escrowWNOK(escrowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of own asset references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgeApi
     */
    public getBridgeStatus(options?: any) {
        return BridgeApiFp(this.configuration).getBridgeStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of own asset references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgeApi
     */
    public getOwnAssetRefs(options?: any) {
        return BridgeApiFp(this.configuration).getOwnAssetRefs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/info/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the Besu address of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnAddress: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/getOwnAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContracts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await InfoApiAxiosParamCreator(configuration).getContracts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the Besu address of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnAddress(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InfoApiAxiosParamCreator(configuration).getOwnAddress(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(options?: any): AxiosPromise<Array<Contract>> {
            return InfoApiFp(configuration).getContracts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the Besu address of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnAddress(options?: any): AxiosPromise<string> {
            return InfoApiFp(configuration).getOwnAddress(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getContracts(options?: any) {
        return InfoApiFp(this.configuration).getContracts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the Besu address of the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getOwnAddress(options?: any) {
        return InfoApiFp(this.configuration).getOwnAddress(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Submit the order to purchase a security.
         * @param {CreateOrder} [createOrder] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (createOrder?: CreateOrder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrder !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrder !== undefined ? createOrder : {}) : (createOrder || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the orders carrying the id provided.
         * @param {string} id The ID of the order to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOrder.');
            }
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all orders which are visible to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redeems the order.
         * @param {string} id The ID of the order to redeem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling redeemOrder.');
            }
            const localVarPath = `/api/orders/{id}/redeem`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Submit the order to purchase a security.
         * @param {CreateOrder} [createOrder] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(createOrder?: CreateOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).createOrder(createOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the orders carrying the id provided.
         * @param {string} id The ID of the order to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).getOrder(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all orders which are visible to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).getOrders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Redeems the order.
         * @param {string} id The ID of the order to redeem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).redeemOrder(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Submit the order to purchase a security.
         * @param {CreateOrder} [createOrder] A purchase request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(createOrder?: CreateOrder, options?: any): AxiosPromise<Order> {
            return OrderApiFp(configuration).createOrder(createOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the orders carrying the id provided.
         * @param {string} id The ID of the order to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: string, options?: any): AxiosPromise<Order> {
            return OrderApiFp(configuration).getOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all orders which are visible to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(options?: any): AxiosPromise<Array<Order>> {
            return OrderApiFp(configuration).getOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redeems the order.
         * @param {string} id The ID of the order to redeem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemOrder(id: string, options?: any): AxiosPromise<Order> {
            return OrderApiFp(configuration).redeemOrder(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Submit the order to purchase a security.
     * @param {CreateOrder} [createOrder] A purchase request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createOrder(createOrder?: CreateOrder, options?: any) {
        return OrderApiFp(this.configuration).createOrder(createOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the orders carrying the id provided.
     * @param {string} id The ID of the order to fetch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrder(id: string, options?: any) {
        return OrderApiFp(this.configuration).getOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all orders which are visible to the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrders(options?: any) {
        return OrderApiFp(this.configuration).getOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redeems the order.
     * @param {string} id The ID of the order to redeem.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public redeemOrder(id: string, options?: any) {
        return OrderApiFp(this.configuration).redeemOrder(id, options).then((request) => request(this.axios, this.basePath));
    }
}


