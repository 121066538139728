import IssueBondForm from "../components/IssueBondForm";

const IssueBondPage = () => {
  return (
    <div className="content-area">
      <h2>Issue a new bond</h2>
      {IssueBondForm()}
    </div>
  );
};

export default IssueBondPage;
