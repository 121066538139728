import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Alert,
  createTheme,
  CssBaseline,
  Divider,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import AppBar from "./components/AppBar";
import AccountPage from "./pages/AccountPage";
import BuyBondPage from "./pages/BuyBondPage";
import BridgePage from "./pages/BridgePage";
import InfoPage from "./pages/InfoPage";
import NoPage from "./pages/NoPage";
import { tabName } from "./config/Environment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { setAlert } from "./reducers/globalSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import IssueBondPage from "./pages/IssueBondPage";

const locale = "nb";
dayjs.locale(locale);

const ErrorDisplayer = () => {
  const alert = useSelector((state: RootState) => state.global.alert);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => dispatch(setAlert(undefined)), 5000);
      return () => clearTimeout(timer);
    }
  }, [alert, dispatch]);

  if (!alert) return null;

  return (
    <Snackbar
      open={Boolean(alert)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={alert.severity} variant="filled" sx={{ width: "100%" }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

function App() {
  React.useEffect(() => {
    document.title = tabName ?? "Securities App";
  });

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6BFFEF",
      },
      secondary: {
        main: "#DFFFE0",
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="draw-area">
          <AppBar />
          <ErrorDisplayer />
          <Routes>
            <Route path="/" element={<AccountPage />} />
            <Route path="/buy" element={<BuyBondPage />} />
            <Route path="/issue" element={<IssueBondPage />} />
            <Route path="/bridge" element={<BridgePage />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
          <Divider sx={{ my: 5 }} />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
