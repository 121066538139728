import RedeemTable from "../components/RedeemTable";

const AccountPage = () => {
  return (
    <div className="content-area">
      <h2>My orders</h2>
      {RedeemTable()}
    </div>
  );
};

export default AccountPage;
