import BuyTable from "../components/BuyTable";

const BuyBondPage = () => {
  return (
    <div className="content-area">
      <h2>Buy bonds</h2>
      {BuyTable()}
    </div>
  );
};

export default BuyBondPage;
