import Box from "@mui/material/Box";
import ContractsTable from "../components/ContractsTable";

const InfoPage = () => {
  return (
    <div className="content-area">
      <Box sx={{ mx: "auto", width: "50%" }}>
        <h2>Contracts in use</h2>
        {ContractsTable()}
      </Box>
    </div>
  );
};

export default InfoPage;
