import React, { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { BankMapping, banksNameMapping } from "../config/Environment";
import { GetAccessToken } from "../services/Keycloak";
import { jwtDecode } from "jwt-decode";

interface NamesListProps {
  onSelect: (value: string) => void;
  bankNameType: "fabricName" | "utxoName" | "besuAddress";
  placeholder: string;
}

type OptionType = {
  value: string;
  label: string;
};

const DropdownMenu: React.FC<NamesListProps> = ({
  onSelect,
  bankNameType,
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

  const mapping = banksNameMapping();

  // Here we pre-select the entity corresponding to the logged in user on the
  // Fabric side using a static mapping from Besu username to Fabric utxoName
  // (JSON file). This is just a workaround until our data model supports this
  // mapping (e.g. Besu users might query the utxoName from the Bridge).
  useEffect(() => {
    async function fetchAccessToken() {
      const accessToken = (await GetAccessToken())!;
      const decodedToken: any = jwtDecode(accessToken);
      const username = decodedToken.preferred_username;
      const bank = mapping.find(
        (value: BankMapping, i: number, obj: BankMapping[]) =>
          value.besuUser === username,
      )!;
      const selectedValue = {
        value: bank.utxoName,
        label: `${bank.legalName} @ Fabric Wholesale`,
      };
      setSelectedOption(selectedValue);
    }
    fetchAccessToken();
  }, []);

  const options: OptionType[] = mapping.map((bank: BankMapping) => ({
    value: bank.utxoName,
    label: `${bank.legalName} @ Fabric Wholesale`,
  }));

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    onSelect(option?.value ?? "");
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: 280,
          fontFamily: "Open Sans, sans-serif",
          marginLeft: 0,
        }),
      }}
      placeholder={placeholder}
      isDisabled={true}
    />
  );
};

export default DropdownMenu;
