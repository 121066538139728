import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { orderApi } from "../apiClient";
import CircularRedeemProgress from "./CircularRedeemProgress";
import formatUtcDate from "./FormatUtcDate";
import { Order } from "../generated-client";
import { useDispatch } from "react-redux";
import { setAlert } from "../reducers/globalSlice";

export default function RedeemTable() {
  const [orders, setOrders] = useState<any[]>([]);
  const [loadingOrder, setLoadingOrder] = useState<boolean>(true);
  const [errorOrder, setErrorOrder] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const options: any = {};
    orderApi
      .getOrders(options)
      .then((response: { data: Order[] }) => {
        const fetchedOrders = response.data;
        const filteredOrders = fetchedOrders
          .filter((order) => order.status !== "requested")
          .toSorted(
            (a, b) =>
              Number(b.zeroBond.maturityTime) - Number(a.zeroBond.maturityTime),
          );
        setOrders(filteredOrders);
        setLoadingOrder(false);
      })
      .catch((error: any) => {
        setErrorOrder("Error fetching orders");
        setLoadingOrder(false);
        dispatch(
          setAlert({
            severity: "error",
            message: "Error fetching order.",
          }),
        );
      });
  }, []);

  if (loadingOrder) {
    return <div>Loading...</div>;
  }

  if (errorOrder) {
    return <div>{errorOrder}</div>;
  }

  if (orders.length === 0) {
    return <div>There are no orders yet for this account.</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ISIN</TableCell>
            <TableCell align="center">Volume</TableCell>
            <TableCell align="center">Total Price</TableCell>
            <TableCell align="center">Total Face Value</TableCell>
            <TableCell align="center">
              Start Time
              <br />
              Maturity Time
            </TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow
              key={order.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{order.zeroBond.isin}</TableCell>
              <TableCell align="center">{order.volume}</TableCell>
              <TableCell align="center">
                {order.volume * order.zeroBond.issuePrice}
              </TableCell>
              <TableCell align="center">
                {order.volume * order.zeroBond.faceValue}
              </TableCell>
              <TableCell align="center">
                {formatUtcDate(order.zeroBond.startTime)}
                <br />
                {formatUtcDate(order.zeroBond.maturityTime)}
              </TableCell>
              <TableCell align="center">
                <CircularRedeemProgress
                  endTime={order.zeroBond.maturityTime}
                  startTime={order.zeroBond.startTime}
                  id={order.id}
                  status={order.status}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
