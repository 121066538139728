import localBankMapping from "./bank-mapping-local.json";
import devBankMapping from "./bank-mapping-dev.json";

export const tabName = process.env.REACT_APP_TAB_NAME;

export const getProxyURL = () => {
  return process.env.REACT_APP_PROXY_URL ?? "FAIL_MISSING_URL";
};

export const getKeyCloakSecured = () => {
  return (
    (process.env.REACT_APP_KEY_CLOAK_SECURED ?? "").toUpperCase() === "YES"
  );
};

export const getPollingInterval = () => {
  return Number(process.env.REACT_APP_POLLING_INTERVAL_MS) || 3000;
};

export const getBalancePollingInterval = () => {
  return Number(process.env.REACT_APP_POLLING_INTERVAL_BALANCE_MS) || 1500;
};

export interface BankMapping {
  legalName: string;
  utxoName: string;
  besuUser: string;
  avatar: string;
}
export const banksNameMapping = (): BankMapping[] => {
  if (process.env.REACT_APP_BANK_MAPPING === "local") {
    return localBankMapping.bankMapping;
  }
  if (process.env.REACT_APP_BANK_MAPPING === "dev") {
    return devBankMapping.bankMapping;
  }
  return [];
};
