import Keycloak from "keycloak-js";
import { getKeyCloakSecured } from "../config/Environment";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? "realm",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID ?? "clientId",
});

if (getKeyCloakSecured()) {
  try {
    console.log(
      "Authenticating to keycloak server, realm, clientId: " +
        process.env.REACT_APP_KEYCLOAK_URL +
        ", " +
        process.env.REACT_APP_KEYCLOAK_REALM +
        ", " +
        process.env.REACT_APP_KEYCLOAK_CLIENTID,
    );
    const authenticated = await keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });
    console.log(
      `User is ${authenticated ? "authenticated" : "not authenticated"}`,
    );
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
} else {
  // Keycloak authentication is disabled, proceed without authentication.
  console.log("Keycloak authentication is disabled. Proceeding without it.");
}

export function Activate() {
  if (getKeyCloakSecured()) {
    console.log("KeyCloak is active");
  } else {
    console.log("KeyCloak is NOT active");
  }
}

export function Logout() {
  if (getKeyCloakSecured()) {
    console.log("Logging out of keycloak");
    keycloak.logout();
  } else {
    console.log("Keycloak disabled -- no real logout");
  }
}

export async function GetAccessToken(): Promise<string | null | undefined> {
  if (getKeyCloakSecured()) {
    // Check the token is valid before continuing
    try {
      await keycloak.updateToken(5);
      console.log("Token was successfully refreshed or is still valid");
    } catch (error) {
      console.log("Failed to refresh the token, or the session has expired");
      return null;
    }
    return keycloak.token;
  } else {
    return null;
  }
}
