import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./reducers/globalSlice"; // Import the globalReducer

const store = configureStore({
  reducer: {
    global: globalReducer, // Add the globalReducer to the store
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // Use only the default middleware
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
