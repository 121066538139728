import * as React from "react";
import Stack from "@mui/material/Stack";
import TokenInputSlider from "../components/TokenInputSlider";
import OwnAssetRefsTable from "../components/OwnAssetRefsTable";
import { bridgeApi, infoApi } from "../apiClient";
import { GridRowId, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { setAlert } from "../reducers/globalSlice";
import { LoadingButton } from "@mui/lab";
import SelectNetworkDropdownMenu from "../components/SelectNetworkDropdownMenu";
import { useEffect } from "react";
import { AssetRef } from "../generated-client";
import { GetAccessToken } from "../services/Keycloak";
import { jwtDecode } from "jwt-decode";
import { BankMapping, banksNameMapping } from "../config/Environment";
import Grid from "@mui/material/Grid";

const BridgePage = () => {
  const maxValue = 1000;
  const dispatch = useDispatch();

  const mapping = banksNameMapping();

  const [escrowRemoteRecipient, setEscrowRemoteRecipient] =
    React.useState<string>("");
  const [isLoadingEscrowWnok, setIsLoadingEscrowWnok] =
    React.useState<boolean>(false);

  const [isLoadingBridgeOut, setIsLoadingBridgeOut] =
    React.useState<boolean>(false);

  const defaultSliderValue = 100;
  const [escrowAmount, setEscrowAmount] =
    React.useState<number>(defaultSliderValue);

  const [assetRefsData, setAssetRefsData] = React.useState<AssetRef[]>([]);
  const [isLoadingGetAssetRefs, setIsLoadingGetAssetRefs] =
    React.useState<boolean>(false);
  const [isUninitializedAssetRefsTable, setIsUninitializedAssetRefsTable] =
    React.useState<boolean>(true);

  const assetRefsGridApiRef = useGridApiRef();

  const handleEscrowAmountChangeIssue = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setEscrowAmount(newValue);
    } else if (event.target) {
      setEscrowAmount(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleEscrowClick = async () => {
    let recipient = escrowRemoteRecipient;

    if (!recipient) {
      const accessToken = (await GetAccessToken())!;
      const decodedToken: any = jwtDecode(accessToken);
      const username = decodedToken.preferred_username;
      const bank = mapping.find(
        (value: BankMapping, i: number, obj: BankMapping[]) =>
          value.besuUser === username,
      )!;
      setEscrowRemoteRecipient(bank.utxoName);
      recipient = bank.utxoName;
    }

    if (escrowAmount && recipient) {
      console.log(
        "handleEscrowClick event with: " + escrowAmount + " " + recipient,
      );
      setIsLoadingEscrowWnok(true);
      bridgeApi
        .escrowWNOK({
          remoteNetwork: "fabric",
          amount: escrowAmount,
          remoteRecipient: recipient,
        })
        .then(handleCompleteEscrow);
    } else {
      dispatch(
        setAlert({
          severity: "warning",
          message: "Please enter an amount and select a recipient.",
        }),
      );
    }
  };

  const fetchAssetRefs = async () => {
    setIsLoadingGetAssetRefs(true);
    try {
      const response = await bridgeApi.getOwnAssetRefs();
      setAssetRefsData(response.data);
      setIsUninitializedAssetRefsTable(false);
    } catch (error) {
      console.error("Error fetching AssetRefs:", error);
      dispatch(
        setAlert({
          severity: "error",
          message: "Failed to fetch AssetRefs.",
        }),
      );
    } finally {
      setIsLoadingGetAssetRefs(false);
    }
  };

  const handleBridgeOutClick = () => {
    if (assetRefsGridApiRef.current) {
      const selectedRowIds = assetRefsGridApiRef.current.getSelectedRows();

      if (selectedRowIds.size === 1) {
        let bridgeOutAssetRef = selectedRowIds.get(
          // The next line triggers a false positive for TS2345 in CI only
          // so cast to GridRowId
          selectedRowIds.keys().next().value as GridRowId,
        );

        if (bridgeOutAssetRef) {
          console.log(
            "handleBridgeOutClick event with: " +
              bridgeOutAssetRef.id +
              " for own address: " +
              infoApi.getOwnAddress(),
          );

          setIsLoadingBridgeOut(true);
          bridgeApi
            .bridgeOutWNOK({
              AssetRefId: bridgeOutAssetRef.id,
              Amount: bridgeOutAssetRef.Amount,
              RemoteRecipient: bridgeOutAssetRef.RemoteRecipient,
            })
            .then(handleCompleteBridgeOut)
            .catch((error: any) => {
              console.error("Error bridging out AssetRef:", error);
              dispatch(
                setAlert({
                  severity: "error",
                  message: "Failed to bridge out AssetRef.",
                }),
              );
            });
        }
      } else {
        dispatch(
          setAlert({
            severity: "warning",
            message: "Please bridge out only one AssetRef at a time.",
          }),
        );
      }
    }
  };

  const handleCompleteEscrow = () => {
    dispatch(
      setAlert({
        severity: "success",
        message: "Escrow completed successfully.",
      }),
    );
    setIsLoadingEscrowWnok(false);
  };

  const handleCompleteBridgeOut = async () => {
    dispatch(
      setAlert({
        severity: "success",
        message: "Bridging completed successfully.",
      }),
    );
    await fetchAssetRefs();
    setIsLoadingBridgeOut(false);
  };

  useEffect(() => {
    if (isUninitializedAssetRefsTable) {
      fetchAssetRefs();
    }
  }, [isUninitializedAssetRefsTable]);

  const handleGetAssetRefsClick = async () => {
    await fetchAssetRefs();
  };

  return (
    <div className="content-area">
      <h2>Escrow tokens</h2>
      <Stack spacing={2} direction="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item marginLeft={0}>
            <SelectNetworkDropdownMenu
              onSelect={setEscrowRemoteRecipient}
              bankNameType="besuAddress"
              placeholder="Select a recipient"
            />
          </Grid>
          <Grid item xs>
            <TokenInputSlider
              value={escrowAmount}
              maxValue={maxValue}
              onChange={handleEscrowAmountChangeIssue}
              defaultSliderValue={defaultSliderValue}
            />
          </Grid>
          <Grid item marginRight={1}>
            <LoadingButton
              loading={isLoadingEscrowWnok}
              variant="text"
              onClick={handleEscrowClick}
            >
              Escrow
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
      <h2>Bridge out tokens</h2>
      {OwnAssetRefsTable(assetRefsData, assetRefsGridApiRef)}
      <LoadingButton
        loading={isLoadingGetAssetRefs}
        variant="text"
        onClick={handleGetAssetRefsClick}
      >
        Refresh
      </LoadingButton>
      <LoadingButton
        loading={isLoadingBridgeOut}
        variant="text"
        onClick={handleBridgeOutClick}
      >
        Bridge Out
      </LoadingButton>
    </div>
  );
};

export default BridgePage;
