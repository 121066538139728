import React, { useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BankMapping, banksNameMapping } from "../config/Environment";
import { GetAccessToken } from "../services/Keycloak";
import { jwtDecode } from "jwt-decode";

export default function BasicTable(
  assetRefs: Array<any> | undefined,
  assetRefsGridApiRef: React.MutableRefObject<any>,
) {
  const emptyTableMessage = !assetRefs ? "Loading..." : "No asset refs found.";
  const recipientFilter = useRef<string>("");

  const mapping = banksNameMapping();

  // Here we pre-select the entity corresponding to the logged in user on the
  // Fabric side using a static mapping from Besu username to Fabric utxoName
  // (JSON file). This is just a workaround until our data model supports this
  // mapping (e.g. Besu users might query the utxoName from the Bridge).
  useEffect(() => {
    async function fetchAccessToken() {
      const accessToken = (await GetAccessToken())!;
      const decodedToken: any = jwtDecode(accessToken);
      const username = decodedToken.preferred_username;
      const bank = mapping.find(
        (value: BankMapping, i: number, obj: BankMapping[]) =>
          value.besuUser === username,
      )!;
      recipientFilter.current = bank.utxoName;
    }
    fetchAccessToken();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Asset Ref ID", width: 300 },
    { field: "RemoteRecipient", headerName: "Remote Recipient", width: 300 },
    { field: "Amount", headerName: "Amount", width: 100 },
  ];

  const rows = !assetRefs
    ? []
    : assetRefs.map((assetRef) => ({
        id: assetRef.AssetRefId,
        RemoteRecipient: assetRef.RemoteRecipient,
        Amount: assetRef.refwNOKAmount,
      }));

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows.filter((r) => r.RemoteRecipient === recipientFilter.current)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        checkboxSelection
        disableRowSelectionOnClick
        disableMultipleRowSelection={true}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {emptyTableMessage}
            </Stack>
          ),
        }}
        apiRef={assetRefsGridApiRef}
      />
    </Box>
  );
}
