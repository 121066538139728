import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface DateTimePickerValueProps {
  onChange: (value: Dayjs) => void;
  label: string;
}

export default function DateTimePickerValue({
  onChange,
  label,
}: Readonly<DateTimePickerValueProps>) {
  const now = dayjs();
  const [value, setValue] = React.useState<Dayjs | null>(now);

  const handleDateChange = (newValue: Dayjs | null) => {
    try {
      if (newValue) {
        setValue(newValue);
        onChange(newValue);
      } else {
        setValue(null);
      }
    } catch (error) {
      setValue(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
        <DateTimePicker
          value={value}
          label={label}
          onChange={handleDateChange}
          minDateTime={dayjs().subtract(10, "second")}
          timezone="system"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
