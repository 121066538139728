import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Alert {
  severity: "error" | "warning" | "success" | "info";
  message: string;
}

interface InitialGlobalState {
  alert: undefined | Alert;
}
const initialState: InitialGlobalState = {
  alert: undefined,
};

export const globalSlice = createSlice({
  name: "global_state",
  initialState: initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert | undefined>) => {
      state.alert = action.payload;
    },
  },
});

export const { setAlert } = globalSlice.actions;

export default globalSlice.reducer;
