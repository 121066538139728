import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { orderApi } from "../apiClient";
import { setAlert } from "../reducers/globalSlice";
import { useDispatch } from "react-redux";

interface CircularProgressProps {
  startTime: number; // UTC seconds
  endTime: number; // UTC seconds
  id: string;
  status: string;
}

const CircularRedeemProgress: React.FC<CircularProgressProps> = ({
  startTime,
  endTime,
  id,
  status,
}) => {
  const [progress, setProgress] = useState(0);
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [errorOrder, setErrorOrder] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<string>("");
  const [isRedeemed, setIsRedeemed] = useState<boolean>(status === "redeemed");

  const dispatch = useDispatch();

  useEffect(() => {
    const calculateProgress = () => {
      const now = new Date().getTime();
      const start = new Date(startTime * 1000).getTime();
      const end = new Date(endTime * 1000).getTime();

      if (start > now) {
        setProgress(0);
        setTimeLeft("Not\nStarted");
      } else if (now >= end) {
        setProgress(1);
        setTimeLeft("00:00:00");
      } else {
        const totalTime = end - start;
        const elapsedTime = now - start;
        const progressValue = Math.max(0, Math.min(1, elapsedTime / totalTime));
        setProgress(progressValue);

        const remainingTime = end - now;
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        setTimeLeft(
          `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    };

    calculateProgress();
    const interval = setInterval(calculateProgress, 200);

    return () => clearInterval(interval);
  });

  const handleRedeemOrder = async () => {
    setLoadingOrder(true);
    setErrorOrder(null);
    try {
      const response = await orderApi.redeemOrder(id);
      console.log("Bond redeemed successfully:", response.data);
      dispatch(
        setAlert({
          severity: "success",
          message: "Bond redeemed successfully.",
        }),
      );
      setIsRedeemed(true);
    } catch (error) {
      console.error("Error redeeming bond:", error);
      dispatch(
        setAlert({
          severity: "error",
          message: "Failed to redeem bond.",
        }),
      );
    } finally {
      setLoadingOrder(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      {progress === 1 ? (
        <Box mt={1}>
          <Button
            variant="contained"
            color={isRedeemed ? "secondary" : "primary"}
            onClick={handleRedeemOrder}
            disabled={loadingOrder || isRedeemed}
          >
            {loadingOrder ? "Redeeming..." : isRedeemed ? "Redeemed" : "Redeem"}
          </Button>
        </Box>
      ) : (
        <div style={{ position: "relative", width: "50px", height: "50px" }}>
          <CircularProgressbar value={progress * 100} />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "10px",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {timeLeft}
          </div>
        </div>
      )}
      {errorOrder && <Typography color="error">{errorOrder}</Typography>}
    </Box>
  );
};

export default CircularRedeemProgress;
