import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { balanceApi, bondApi, orderApi } from "../apiClient";
import { CreateOrder, ZeroBond, ZeroBondStatusEnum } from "../generated-client";
import { useDispatch } from "react-redux";
import { setAlert } from "../reducers/globalSlice";
import formatUtcDate from "./FormatUtcDate";

function BuyTable() {
  const [bonds, setBonds] = useState<Array<ZeroBond>>(new Array<ZeroBond>());
  const [loadingBonds, setLoadingBonds] = useState<boolean>(true);
  const [volumes, setVolumes] = useState<number[]>([]);
  const [totalPrices, setTotalPrices] = useState<number[]>([]);
  const [maturityValues, setMaturityValues] = useState<number[]>([]);
  const [buyingStatuses, setBuyingStatuses] = useState<boolean[]>([]);
  const [balance, setBalance] = useState<number>(0);

  const activePurchase = useRef<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const options: any = {};
    balanceApi
      .getBalance(options)
      .then((response: { data: any }) => {
        setBalance(response.data);
      })
      .catch(() => {
        dispatch(
          setAlert({
            severity: "error",
            message: "Error fetching balance.",
          }),
        );
      });
  }, []);

  const fetchBonds = async () => {
    if (activePurchase.current) {
      return;
    }
    try {
      const response = await bondApi.getBonds();
      const allBonds = response.data;
      // Filter: Show only deployed bonds
      // TODO: Replace `.toSorted` with Table-based sorting in the rendering step
      const deployedBonds = allBonds
        .filter((bond) => bond.status === ZeroBondStatusEnum.Deployed)
        .toSorted((a, b) => Number(b.startTime) - Number(a.startTime));
      setBonds(deployedBonds);
      setTotalPrices(deployedBonds.map((bond) => bond.issuePrice));
      setMaturityValues(deployedBonds.map((bond) => bond.faceValue));
      setBuyingStatuses(new Array(deployedBonds.length).fill(false));
      setLoadingBonds(false);
    } catch (error) {
      dispatch(
        setAlert({
          severity: "error",
          message: "Error fetching bonds.",
        }),
      );
    }
  };

  useEffect(() => {
    fetchBonds();
    const pollingInterval = 3000;
    const intervalId = setInterval(fetchBonds, pollingInterval);
    return () => clearInterval(intervalId);
  }, []);

  const handleVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const newVolume = Number(event.target.value);
    const newVolumes = [...volumes];
    newVolumes[index] = newVolume;
    setVolumes(newVolumes);

    const newTotalPrices = [...totalPrices];
    newTotalPrices[index] = newVolume * bonds[index].issuePrice;
    setTotalPrices(newTotalPrices);

    const newMaturityValues = [...maturityValues];
    newMaturityValues[index] = newVolume * bonds[index].faceValue;
    setMaturityValues(newMaturityValues);
  };

  const handleBuy = async (index: number) => {
    const volume = volumes[index];
    if (!volume || volume < 1) {
      dispatch(
        setAlert({
          severity: "warning",
          message: "Please enter a valid volume.",
        }),
      );
      return;
    }

    if (totalPrices[index] > balance) {
      dispatch(
        setAlert({
          severity: "warning",
          message: "Insufficient funds.",
        }),
      );
      return;
    }

    const createOrder: CreateOrder = {
      isin: bonds[index].isin,
      volume: volume,
    };

    setBuyingStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = true;
      activePurchase.current = true;
      return newStatuses;
    });

    try {
      await orderApi.createOrder(createOrder);
      dispatch(
        setAlert({
          severity: "success",
          message: "Order completed successfully.",
        }),
      );
      activePurchase.current = false;
      setBuyingStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses];
        newStatuses[index] = false;
        return newStatuses;
      });
    } catch (error) {
      console.error("Error completing order:", error);
      dispatch(
        setAlert({
          severity: "error",
          message: "Failed to complete order.",
        }),
      );
      setBuyingStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses];
        newStatuses[index] = false;
        return newStatuses;
      });
    }
  };

  const isBuyButtonDisabled = (index: number) => {
    const now = new Date().getTime();
    const startTime = new Date(Number(bonds[index].startTime) * 1000).getTime();
    return now > startTime;
  };

  if (loadingBonds) {
    return <div>Loading...</div>;
  }

  if (bonds.length === 0) {
    return <div>No bonds have been issued yet.</div>;
  }

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ISIN</TableCell>
              <TableCell align="center">Issue Price</TableCell>
              <TableCell align="center">Face Value</TableCell>
              <TableCell align="center">Total Volume</TableCell>
              <TableCell align="center">Total Price</TableCell>
              <TableCell align="center">Maturity Value</TableCell>
              <TableCell align="center">
                Start Time
                <br />
                Maturity Time
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bonds.map((bond: ZeroBond, index: number) => (
              <TableRow
                key={bond.isin}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{bond.isin}</TableCell>
                <TableCell align="center">{bond.issuePrice}</TableCell>
                <TableCell align="center">{bond.faceValue}</TableCell>
                <TableCell align="center">
                  <TextField
                    id="outlined-number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                      max: bond.totalVolume,
                      style: { fontSize: "14px" },
                    }}
                    sx={{ width: "80px" }}
                    value={volumes[index]}
                    size="small"
                    onChange={(e) => handleVolumeChange(e, index)}
                    disabled={isBuyButtonDisabled(index)}
                  />
                </TableCell>
                <TableCell align="center">{totalPrices[index]}</TableCell>
                <TableCell align="center">{maturityValues[index]}</TableCell>
                <TableCell align="center">
                  {formatUtcDate(Number(bond.startTime))}
                  <br />
                  {formatUtcDate(Number(bond.maturityTime))}
                </TableCell>
                <TableCell align="right">
                  <LoadingButton
                    variant="contained"
                    loading={buyingStatuses[index]}
                    onClick={() => handleBuy(index)}
                    disabled={isBuyButtonDisabled(index)}
                  >
                    Buy
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default BuyTable;
