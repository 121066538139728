import { useState, useEffect } from "react";
import { balanceApi } from "../apiClient";
import { useDispatch } from "react-redux";
import { setAlert } from "../reducers/globalSlice";
import { getBalancePollingInterval } from "../config/Environment";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";

const BalanceWidget = () => {
  const [balance, setBalance] = useState<any>([]);
  const [loadingBalance, setLoadingBalance] = useState<boolean>(true);
  const [errorBalance, setErrorBalance] = useState<string | null>(null);

  const dispatch = useDispatch();

  const fetchBalance = async () => {
    const options: any = {};
    try {
      const response = await balanceApi.getBalance(options);
      setBalance(response.data);
      setLoadingBalance(false);
    } catch (error) {
      setErrorBalance("Error fetching balance");
      setLoadingBalance(false);
      dispatch(
        setAlert({
          severity: "error",
          message: "Error fetching balance.",
        }),
      );
    }
  };

  useEffect(() => {
    fetchBalance();
    const intervalId = setInterval(fetchBalance, getBalancePollingInterval());
    return () => clearInterval(intervalId);
  }, []);

  if (loadingBalance) {
    return <div>Loading...</div>;
  }

  if (errorBalance) {
    return <div>{errorBalance}</div>;
  }

  return (
    <Typography
      variant="body1"
      component="a"
      sx={{
        mr: 1,
        display: { xs: "none", md: "flex" },
        fontWeight: 100,
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <NumericFormat
        value={balance}
        thousandSeparator="'"
        displayType="text"
        suffix=" "
      />
      &nbsp; NOK
    </Typography>
  );
};

export default BalanceWidget;
