import * as React from "react";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import { CreateZeroBond } from "../generated-client";
import TokenInputSlider from "./TokenInputSlider";
import DateTimePickerValue from "./DateTimePickerValue";
import { useState } from "react";
import { bondApi } from "../apiClient";
import { setAlert } from "../reducers/globalSlice";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import dayjs, { Dayjs } from "dayjs";

const IssueBondForm = () => {
  const maxValueVolume = 200;
  const maxValuePrice = 50;
  const defaultTotalVolume = 100;
  const defaultIssuePrice = 5;
  const defaultFaceValue = defaultIssuePrice * 2;

  const now = dayjs();
  const [totalVolume, setTotalVolume] = useState<number>(defaultTotalVolume);
  const [issuePrice, setIssuePrice] = useState<number>(defaultIssuePrice);
  const [faceValue, setFaceValue] = useState<number>(defaultFaceValue);
  const [startTime_, setStartTime_] = useState<Dayjs>(now);
  const [maturityTime_, setMaturityTime_] = useState<Dayjs>(now);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const handleCreateBond = async () => {
    if (startTime_.isAfter(maturityTime_)) {
      dispatch(
        setAlert({
          severity: "warning",
          message: "Maturity time cannot be before start time.",
        }),
      );
      return;
    }

    if (startTime_.isBefore(dayjs())) {
      dispatch(
        setAlert({
          severity: "warning",
          message: "Start time cannot be in the past.",
        }),
      );
      return;
    }

    setLoading(true);
    setError(null);

    const startTime = startTime_.toISOString();
    const maturityTime = maturityTime_.toISOString();

    const createZeroBond: CreateZeroBond = {
      totalVolume,
      startTime,
      maturityTime,
      issuePrice,
      faceValue,
    };

    try {
      const response = await bondApi.createBond(createZeroBond);
      console.log("Bond created successfully:", response.data);
      dispatch(
        setAlert({
          severity: "success",
          message: "Bond created successfully.",
        }),
      );
    } catch (error) {
      console.error("Error creating bond:", error);
      setError("Failed to issue bond.");
      dispatch(
        setAlert({
          severity: "error",
          message: "Failed to issue bond.",
        }),
      );
    }
    setLoading(false);
  };

  const handleVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setTotalVolume(newValue);
    } else if (event.target) {
      setTotalVolume(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleIssuePriceChange = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setIssuePrice(newValue);
    } else if (event.target) {
      setIssuePrice(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleFaceValueChange = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setFaceValue(newValue);
    } else if (event.target) {
      setFaceValue(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleStartTimeChange = (newDate: Dayjs) => {
    setStartTime_(newDate);
  };

  const handleMaturityTimeChange = (newDate: Dayjs) => {
    setMaturityTime_(newDate);
  };

  return (
    <div className="content-area">
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Stack spacing={2} direction="column">
        <div>
          <label>
            Total Volume
            <TokenInputSlider
              value={totalVolume}
              maxValue={maxValueVolume}
              onChange={handleVolumeChange}
              defaultSliderValue={defaultTotalVolume}
            />
          </label>
          <label>
            Issue Price
            <TokenInputSlider
              value={issuePrice}
              maxValue={maxValuePrice}
              onChange={handleIssuePriceChange}
              defaultSliderValue={defaultIssuePrice}
            />
          </label>
          <label>
            Face Value
            <TokenInputSlider
              value={faceValue}
              maxValue={maxValuePrice}
              onChange={handleFaceValueChange}
              defaultSliderValue={defaultFaceValue}
            />
          </label>
        </div>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <label>
            <DateTimePickerValue
              label="Start Time"
              onChange={handleStartTimeChange}
            />
          </label>
        </Grid>
        <Grid item xs>
          <label>
            <DateTimePickerValue
              label="Maturity Time"
              onChange={handleMaturityTimeChange}
            />
          </label>
        </Grid>
      </Grid>
      <Stack direction="column">
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleCreateBond}
          sx={{ marginTop: 2 }}
        >
          Issue Bond
        </LoadingButton>
      </Stack>
    </div>
  );
};

export default IssueBondForm;
